export const defaultPrinterOptions = [
  { label: "DMP Factory 350", value: "DMP Factory 350" },
  { label: "DMP Factory 500 Solution", value: "DMP Factory 500 Solution" },
  { label: "ProJet MJP 2500", value: "ProJet MJP 2500" },
  { label: "ProJet MJP 2500 Plus", value: "ProJet MJP 2500 Plus" },
];
export const defaultTechOptions = [
  { label: "ColorJet Printing", value: "CJP" },
  { label: "Direct Light Processing", value: "DLP" },
  { label: "Direct Metal Printing", value: "DMP" },
  { label: "Extrusion", value: "Extrusion" },
  { label: "MultiJet Printing", value: "MJP" },
];
export const defaultIndustryOptions = [
  { label: "ColorJet Printing", value: "CJP" },
  { label: "Direct Light Processing", value: "DLP" },
  { label: "Direct Metal Printing", value: "DMP" },
  { label: "Extrusion", value: "Extrusion" },
  { label: "MultiJet Printing", value: "MJP" },
];
export const defaultApplicationOptions = [
  { label: "ColorJet Printing", value: "CJP" },
  { label: "Direct Light Processing", value: "DLP" },
  { label: "Direct Metal Printing", value: "DMP" },
  { label: "Extrusion", value: "Extrusion" },
  { label: "MultiJet Printing", value: "MJP" },
];
export const defaultMaterialTypeOptions = [
  { label: "ABS", value: "Apple" },
  { label: "Aleacion de aluminio", value: "Pear" },
  { label: "Cromo-cobalto", value: "Orange" },
];
