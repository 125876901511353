export const mainColumns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Color",
    dataIndex: "color",
    key: "color",
    width: 200,
  },
  {
    title: "Descripcion",
    dataIndex: "description",
    key: "description",
    width: 700,
  },
];
